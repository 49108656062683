import * as React from "react";
import { ProgramStackScreenProps } from "../types/navigation";
import { ScrollView, Text } from "../components/Themed";

import { StyleSheet } from "react-native";
import { useAppData } from "../AppData";

export function ActivityDetailsScreen({
  route,
}: ProgramStackScreenProps<"ActivityDetails">) {
  const { day, activity } = route.params;
  const { schedule } = useAppData();
  const details = schedule[day].activities[activity].details;
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ paddingBottom: 40 }}
    >
      <Text style={{ fontSize: 16 }}>{details}</Text>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
});
