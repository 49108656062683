/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";

import { RootTabParamList } from "../types/navigation";

const linking: LinkingOptions<RootTabParamList> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Program: {
        screens: {
          Program: "program",
          Day: {
            path: "program/:day",
            parse: {
              day: Number,
            },
          },
          Schedule: {
            path: "program/:day/schedule",
            parse: {
              day: Number,
            },
          },
          ActivityDetails: {
            path: "program/:day/activities/:activity",
            parse: {
              day: Number,
              activity: Number,
            },
          },
          Assignment: {
            path: "program/:day/assignment",
            parse: {
              day: Number,
            },
          },
          Tips: {
            path: "program/:day/tips",
            parse: {
              day: Number,
            },
          },
          Info: {
            path: "program/:day/info",
            parse: {
              day: Number,
            },
          },
        },
      },
      Translator: "translator",
      Contacts: "contacts",
    },
  },
};

export default linking;
