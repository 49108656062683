import { useEffect, useState } from "react";
import { Platform, Pressable, StyleSheet, TextInput } from "react-native";

import * as gTranslate from "../api/google-translate";
import { Text, View } from "../components/Themed";
import { RootTabScreenProps } from "../types/navigation";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAppData } from "../AppData";

export default function TranslatorScreen({
  navigation,
}: RootTabScreenProps<"Translator">) {
  const [text, setText] = useState("");
  const [translation, setTranslation] = useState("");
  const appdata = useAppData();
  const { dirtySentences } = appdata;

  useEffect(() => setTranslation(""), [text]);

  function translate() {
    async function performTranslation() {
      try {
        setTranslation("Aan het vertalen...");
        const textToTranslate =
          (dirtySentences &&
            Math.random() > 0.5 &&
            dirtySentences[
              Math.floor(Math.random() * dirtySentences.length)
            ]) ||
          text;
        const response = await gTranslate.translate(textToTranslate, {
          from: "nl",
          to: "hr",
        });
        setTranslation(response);
      } catch (e) {
        setTranslation("Vertalen niet gelukt ;/");
        console.error(e);
      }
    }
    performTranslation();
  }

  return (
    <SafeAreaView style={styles.container}>
      {Platform.OS === "web" ? (
        <Text selectable={true} style={styles.text}>
          Deze functie wordt helaas niet ondersteund in de browser
        </Text>
      ) : (
        <>
          <TextInput
            autoFocus
            style={styles.text}
            value={text}
            onChangeText={setText}
            returnKeyLabel="Vertalen"
            returnKeyType="send"
            onSubmitEditing={translate}
            placeholder="Geef tekst op"
          />
          <View
            style={styles.separator}
            lightColor="#eee"
            darkColor="rgba(255,255,255,0.1)"
          />
          {!!translation && (
            <Text selectable={true} style={styles.text}>
              {translation}
            </Text>
          )}
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  text: {
    fontSize: 20,
    padding: 16,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
    alignSelf: "center",
  },
});
