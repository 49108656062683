import React from "react";
import { Text, View } from "../Themed";
import { hourSize } from "./DaySchedule";

export function HourEntry({ hour }: { hour: string }) {
  return (
    <View
      style={{
        height: hourSize,
        paddingRight: 8,
        opacity: 0.4,
      }}
    >
      <Text>{hour}</Text>
    </View>
  );
}
