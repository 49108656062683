import React from "react";
import { Activity } from "../../types/schedule";
import { Text, View } from "../Themed";

export function ActivityEntry({ activity }: { activity: Activity }) {
  return (
    <View
      style={{
        borderLeftWidth: 4,
        borderLeftColor: "#1E2E5D",
        backgroundColor: "#EEF2FC",
        flex: 1,
        padding: 8,
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <View
        style={{
          backgroundColor: activity.hidden ? "rgba(0,0,0,.8)" : "transparent",
          alignSelf: "flex-start",
          overflow: "hidden",
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontWeight: "700",
            opacity: activity.hidden ? 0 : 1,
          }}
        >
          {activity.title}
        </Text>
      </View>
      <Text>
        {activity.startTimeString} - {activity.endTimeString}
      </Text>
    </View>
  );
}
