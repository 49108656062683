import * as React from "react";
import { ProgramStackScreenProps } from "../types/navigation";
import { View } from "../components/Themed";

import { StyleSheet } from "react-native";
import { DaySchedule } from "../components/schedule/DaySchedule";
import { useAppData } from "../AppData";

export function ScheduleScreen({
  route,
  navigation,
}: ProgramStackScreenProps<"Schedule">) {
  const { day } = route.params;
  const { schedule } = useAppData();
  const dayData = schedule[day];
  return (
    <View style={styles.container}>
      <DaySchedule
        key={day}
        activities={dayData?.activities || []}
        onSelectActivity={(activity) =>
          navigation.navigate("ActivityDetails", { day, activity })
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: "bold",
  },
});
