import * as React from "react";
import { ProgramStackScreenProps } from "../types/navigation";
import { ScrollView, Text, View } from "../components/Themed";

import { StyleSheet } from "react-native";
import { useAppData } from "../AppData";

export function TipsScreen({ route }: ProgramStackScreenProps<"Tips">) {
  const { day } = route.params;
  const { schedule } = useAppData();
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ paddingBottom: 40 }}
    >
      {schedule[day].tips.map(({ label, text }, i) => (
        <View key={i} style={{ marginBottom: 16 }}>
          <Text style={{ fontWeight: "bold", fontSize: 16, marginBottom: 2 }}>
            {label}
          </Text>
          <Text style={{ fontSize: 16 }}>{text}</Text>
        </View>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
});
