interface TranslateOptions {
  from: string;
  to: string;
}

const BASE_URL = "https://translate.google.com";

async function getSearchParams() {
  const response = await fetch(BASE_URL);
  const txt = await response.text();
  const sidMatch = txt.match(/"FdrFJe":"(.*?)"/);
  const blMatch = txt.match(/"cfb2h":"(.*?)"/);
  return {
    rpcids: "MkEWBc",
    "source-path": "/",
    "f.sid": (sidMatch && sidMatch[1]) || "",
    bl: (blMatch && blMatch[1]) || "",
    hl: "en-US",
    "soc-app": "1",
    "soc-platform": "1",
    "soc-device": "1",
    _reqid: Math.floor(1000 + Math.random() * 9000).toString(),
    rt: "c",
  };
}

export async function translate(
  text: string,
  opts: TranslateOptions,
  autoCorrect = true
) {
  const params = await getSearchParams();
  const url = `${BASE_URL}/_/TranslateWebserverUi/data/batchexecute?${new URLSearchParams(
    params
  ).toString()}`;
  const freq = [
    [
      [
        params.rpcids,
        JSON.stringify([[text, opts.from, opts.to, autoCorrect], [null]]),
        null,
        "generic",
      ],
    ],
  ];
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: "f.req=" + encodeURIComponent(JSON.stringify(freq)) + "&",
  });
  const txt = await response.text();
  const startOfSize = txt.slice(6);

  const match = /^\d+/.exec(startOfSize);
  const sizeString = (match && match[0]) || "0";
  const size = parseInt(sizeString, 10);
  const data = startOfSize.slice(sizeString.length, size + sizeString.length);
  const json = JSON.parse(JSON.parse(data)[0][2]);
  return json[1][0][0][5]
    .map((obj: string[]) => obj[0])
    .filter(Boolean)
    .join("");
}
