/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { AntDesign } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import {
  ProgramStackScreenProps,
  RootTabParamList,
  RootTabScreenProps,
} from "../types/navigation";
import LinkingConfiguration from "./LinkingConfiguration";
import TranslatorScreen from "../screens/TranslatorScreen";
import ProgramScreen from "../screens/ProgramScreen";
import ContactsScreen from "../screens/ContactsScreen";
import { ActivityDetailsScreen } from "../screens/ActivityDetailsScreen";
import { DayScreen } from "../screens/DayScreen";
import { TipsScreen } from "../screens/TipsScreen";
import { InfoScreen } from "../screens/InfoScreen";
import { ScheduleScreen } from "../screens/ScheduleScreen";
import { AssignmentScreen } from "../screens/AssignmentScreen";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DefaultTheme : DefaultTheme}
    >
      <BottomTabNavigator />
    </NavigationContainer>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Program"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
        headerShown: false,
      }}
    >
      <BottomTab.Screen
        name="Program"
        component={ProgramStack}
        options={({ navigation }: RootTabScreenProps<"Program">) => ({
          title: "Programma",
          tabBarIcon: ({ color, size }) => (
            <TabBarIcon name="calendar" color={color} size={size} />
          ),
        })}
      />
      <BottomTab.Screen
        name="Translator"
        component={TranslatorScreen}
        options={({ navigation }: RootTabScreenProps<"Translator">) => ({
          title: "Vertaler",
          tabBarIcon: ({ color, size }) => (
            <TabBarIcon name="notification" color={color} size={size} />
          ),
          headerShown: true,
        })}
      />
      <BottomTab.Screen
        name="Contacts"
        component={ContactsScreen}
        options={({ navigation }: RootTabScreenProps<"Contacts">) => ({
          title: "Contacten",
          headerShadowVisible: true,
          tabBarIcon: ({ color, size }) => (
            <TabBarIcon name="contacts" color={color} size={size} />
          ),
          headerShown: true,
        })}
      />
    </BottomTab.Navigator>
  );
}

const Stack = createNativeStackNavigator();

function ProgramStack({}) {
  return (
    <Stack.Navigator screenOptions={{ headerShadowVisible: false }}>
      <Stack.Screen
        name="ProgramOverview"
        options={{ title: "Programma" }}
        component={ProgramScreen}
      ></Stack.Screen>
      <Stack.Screen name="Day" component={DayScreen}></Stack.Screen>
      <Stack.Screen
        name="Tips"
        component={TipsScreen}
        options={{
          title: "Tips / te zien",
          headerShown: true,
          headerShadowVisible: true,
        }}
      ></Stack.Screen>
      <Stack.Screen
        name="Schedule"
        options={(props) => {
          const { day } = (props as ProgramStackScreenProps<"Schedule">).route
            .params;
          return {
            title: `Programma dag ${day + 1}`,
            headerShadowVisible: true,
          };
        }}
        component={ScheduleScreen}
      ></Stack.Screen>
      <Stack.Screen
        name="Assignment"
        component={AssignmentScreen}
        options={{
          headerShown: true,
          headerShadowVisible: true,
          title: "Dagopdracht",
        }}
      ></Stack.Screen>
      <Stack.Screen
        name="Info"
        component={InfoScreen}
        options={{
          headerShown: true,
          headerShadowVisible: true,
          title: "Nuttige info",
        }}
      ></Stack.Screen>
      <Stack.Screen
        name="ActivityDetails"
        component={ActivityDetailsScreen}
        options={{
          presentation: "modal",
          headerShown: true,
          headerShadowVisible: true,
          title: "Details",
        }}
      ></Stack.Screen>
    </Stack.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof AntDesign>["name"];
  size: React.ComponentProps<typeof AntDesign>["size"];
  color: string;
}) {
  return <AntDesign {...props} />;
}
