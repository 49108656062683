import { useAsyncStorage } from "@react-native-async-storage/async-storage";
import { Database, get, ref, onValue } from "firebase/database";
import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { Contact } from "./types/contacts";
import { Schedule } from "./types/schedule";

interface AppData {
  contacts: Contact[];
  dirtySentences: string[];
  schedule: Schedule;
  lastUpdated: number;
}

const AppDataContext = createContext<AppData>({
  contacts: [],
  dirtySentences: [],
  schedule: [],
  lastUpdated: 0,
});

export function AppDataProvider({
  database,
  children,
}: {
  database: Database;
  children: ReactNode;
}) {
  const [appData, setAppData] = useState<AppData>({
    contacts: [],
    dirtySentences: [],
    schedule: [],
    lastUpdated: 0,
  });

  const { getItem, setItem } = useAsyncStorage("@app_data");

  const readItemFromStorage = async () => {
    try {
      const item = await getItem();
      if (item) {
        const data = JSON.parse(item) as AppData;
        setAppData(data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const writeItemToStorage = async (newValue: AppData) => {
    try {
      const json = JSON.stringify(newValue);
      await setItem(json);
      setAppData(newValue);
    } catch (e) {
      console.error(e);
    }
  };

  const refreshAppData = async () => {
    try {
      const snapshot = await get(ref(database, "lustrumdata"));
      const newValue = snapshot.val() as AppData;
      writeItemToStorage(newValue);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    readItemFromStorage();
  }, []);

  useEffect(() => {
    onValue(ref(database, "lustrumdata/lastUpdated"), (snapshot) => {
      const timestamp = snapshot.val();
      if (timestamp > appData.lastUpdated) {
        refreshAppData();
      }
    });
  }, []);

  return (
    <AppDataContext.Provider value={appData}>
      {children}
    </AppDataContext.Provider>
  );
}

export function useAppData() {
  return useContext(AppDataContext);
}
