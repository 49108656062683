import React from "react";
import { Pressable } from "react-native";
import { Activity } from "../../types/schedule";
import { View, ScrollView } from "../Themed";
import { ActivityEntry } from "./ActivityEntry";
import { HourEntry } from "./HourEntry";

const hours = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const hourSize = 120;

const TIME_SEPARATOR = ":";

function parseTimeString(str: string) {
  const [hours, minutes] = str.split(TIME_SEPARATOR).map(Number);
  return hours + minutes / 60;
}

function computeActivityPosition(activity: Activity) {
  const position = {
    top: parseTimeString(activity.startTimeString) * hourSize,
    bottom: parseTimeString(activity.endTimeString) * hourSize,
  };
  // Fix end time on next day / undefined
  if (!(position.top < position.bottom)) position.bottom = 24 * hourSize + 8;
  return position;
}

interface DayScheduleProps {
  activities: Activity[];
  onSelectActivity: (activity: number) => void;
}

export function DaySchedule({
  activities,
  onSelectActivity,
}: DayScheduleProps) {
  return (
    <ScrollView
      style={{ flex: 1 }}
      ref={(view) => {
        // TODO: scroll to current time if current day
        const firstActivity = activities[0];
        if (!!firstActivity && !!view) {
          view.scrollTo({
            y: computeActivityPosition(firstActivity).top,
            animated: false,
          });
        }
      }}
      contentContainerStyle={{
        flexDirection: "row",
        padding: 10,
      }}
    >
      <View style={{ marginTop: -10 }}>
        {hours.map((hour) => (
          <HourEntry key={hour} hour={hour} />
        ))}
      </View>

      <View style={{ flex: 1, position: "relative" }}>
        <View
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
          }}
        >
          {hours.map((_, i) => (
            <View
              key={i}
              style={{
                position: "absolute",
                width: "100%",
                height: 1,
                backgroundColor: "black",
                opacity: 0.1,
                top: i * hourSize - 0.5,
                left: 0,
              }}
            ></View>
          ))}
        </View>

        {activities.map((activity, index) => {
          const { top, bottom } = computeActivityPosition(activity);
          return (
            <Pressable
              key={activity.startTimeString}
              style={{
                position: "absolute",
                width: "100%",
                top,
                height: bottom - top,
                backgroundColor: "transparent",
                paddingVertical: 2,
              }}
              disabled={!activity.details}
              onPress={() => onSelectActivity(index)}
            >
              <ActivityEntry activity={activity} />
            </Pressable>
          );
        })}
      </View>
    </ScrollView>
  );
}
