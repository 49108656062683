import { StatusBar } from "expo-status-bar";
import { useEffect, useRef, useState } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Database, getDatabase, push, ref, set } from "firebase/database";
import { AppDataProvider } from "./AppData";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import {
  Button,
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Platform,
  TextInput,
} from "react-native";
import { Subscription } from "expo-modules-core";
import { Text, View } from "./components/Themed";
import { useAsyncStorage } from "@react-native-async-storage/async-storage";

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const logo = require("./assets/images/logo.jpeg");

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQapBWpshEOBj9HgEzYVwk1IGNewxPeT4",
  authDomain: "fubar-lustrum-v.firebaseapp.com",
  databaseURL:
    "https://fubar-lustrum-v-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "fubar-lustrum-v",
  storageBucket: "fubar-lustrum-v.appspot.com",
  messagingSenderId: "234159883561",
  appId: "1:234159883561:web:6265eeec471248eef06338",
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

async function setExpoPushToken(database: Database, token: string) {
  try {
    const tokenId = token.match(/ExponentPushToken\[(\w+)\]/)?.[1];
    if (tokenId) {
      await set(ref(database, `fcmTokens/${tokenId}`), token);
    }
  } catch (e) {
    console.error(e);
  }
}

function useAuth(): [boolean, (state: boolean) => void] {
  const [auth, setAuth] = useState<boolean>(false);

  const { getItem, setItem } = useAsyncStorage("@auth");

  async function checkAuth() {
    try {
      const json = await getItem();
      if (json && JSON.parse(json) === true) {
        setAuth(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return [
    auth,
    (state) => {
      setItem(JSON.stringify(state));
      setAuth(state);
    },
  ];
}

export default function App() {
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const [notification, setNotification] =
    useState<Notifications.Notification>();
  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();
  const [auth, setAuth] = useAuth();
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (Platform.OS !== "web") {
      registerForPushNotificationsAsync().then((token) => {
        if (token) {
          setExpoPushToken(database, token);
        }
      });

      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          setNotification(notification);
        });

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          console.log(response);
        });

      return () => {
        if (notificationListener.current) {
          Notifications.removeNotificationSubscription(
            notificationListener.current
          );
        }
        if (responseListener.current) {
          Notifications.removeNotificationSubscription(
            responseListener.current
          );
        }
      };
    }
  }, []);

  function authenticate() {
    if (password === "Sven Jensen") {
      setAuth(true);
    }
  }

  if (!isLoadingComplete) {
    return null;
  } else {
    if (auth) {
      return (
        <SafeAreaProvider>
          <AppDataProvider database={database}>
            <KeyboardAvoidingView
              style={{ flex: 1 }}
              behavior={Platform.OS === "ios" ? "padding" : "height"}
            >
              <Navigation colorScheme={colorScheme} />
            </KeyboardAvoidingView>
            <StatusBar
              backgroundColor="#1E2E5D"
              style={Platform.OS === "android" ? "light" : "dark"}
            />
          </AppDataProvider>
        </SafeAreaProvider>
      );
    } else {
      return (
        <KeyboardAvoidingView
          style={{
            backgroundColor: "white",
            flex: 1,
            justifyContent: "center",
            padding: 20,
          }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <Image
            style={{
              alignSelf: "center",
              maxWidth: Dimensions.get("window").width / 1.5,
              maxHeight: Dimensions.get("window").width / 1.5,
              resizeMode: "contain",
              aspectRatio: 1,
            }}
            resizeMode="contain"
            source={logo}
          />
          <Text style={{ fontSize: 20, marginVertical: 10 }}>
            Voer het wachtwoord in om toegang te krijgen
          </Text>
          <TextInput
            value={password}
            onChangeText={(value) => setPassword(value)}
            textContentType="password"
            style={{ fontSize: 20, marginBottom: 20 }}
            placeholder="Wachtwoord"
            secureTextEntry
            autoFocus
          />
          <Button title="Go!" onPress={() => authenticate()} />
        </KeyboardAvoidingView>
      );
    }
  }
}

async function registerForPushNotificationsAsync() {
  let token;
  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      console.error("Failed to get push token for push notification!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
  } else {
    console.warn("Must use physical device for Push Notifications");
  }

  if (Platform.OS === "android") {
    Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  return token;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
