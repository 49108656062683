import { FlatList, StyleSheet } from "react-native";

import { View, Text, ScrollView } from "../components/Themed";
import { RootTabScreenProps } from "../types/navigation";
import { ContactList } from "../components/contacts/ContactList";
import { useAppData } from "../AppData";

export default function ContactsScreen({}: RootTabScreenProps<"Contacts">) {
  const { contacts } = useAppData();
  return (
    <View style={styles.container}>
      <ScrollView style={styles.contentContainer}>
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 20 }}>Noodnummer</Text>
          <Text style={{ fontSize: 24, fontWeight: "bold" }}>112</Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: 4 }}>
            Commissie
          </Text>
          <ContactList contacts={contacts.filter((c) => c.organization)} />
        </View>
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 20, fontWeight: "bold", marginBottom: 4 }}>
            Overige leden
          </Text>
          <ContactList contacts={contacts.filter((c) => !c.organization)} />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    padding: 20,
  },
});
