import * as React from "react";
import { ProgramStackScreenProps } from "../types/navigation";
import { ScrollView, Text, View } from "../components/Themed";

import { StyleSheet } from "react-native";
import { useAppData } from "../AppData";

export function AssignmentScreen({
  route,
}: ProgramStackScreenProps<"Assignment">) {
  const { day } = route.params;
  const { schedule } = useAppData();
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ paddingBottom: 40 }}
    >
      <Text style={{ fontSize: 16 }}>{schedule[day].assignment}</Text>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
});
