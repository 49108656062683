import * as React from "react";
import { ProgramStackScreenProps } from "../types/navigation";
import { ScrollView, Text, View } from "../components/Themed";

import { ImageBackground, Platform, Pressable, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  AntDesign,
  Entypo,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import { IconProps } from "@expo/vector-icons/build/createIconSet";

import Images from "../constants/Images";

export function DayScreen({
  route,
  navigation,
}: ProgramStackScreenProps<"Day">) {
  const { day } = route.params;

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerShadowVisible: false,
      headerTransparent: true,
      headerTitle: "",
      headerTintColor: "white",
    });
  });

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView>
        <ImageBackground
          source={Images[day]}
          style={{
            width: "100%",
            minHeight: 200,
            position: "relative",
            backgroundColor: "gray",
          }}
          imageStyle={{ resizeMode: "cover" }}
        >
          <View
            style={{
              backgroundColor: "rgba(0,0,0,.3)",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: 32,
                color: "white",
                textShadowColor: "black",
                textShadowRadius: 30,
                width: "100%",
                padding: 30,
                overflow: "visible",
                textAlign: "center",
              }}
            >
              Dag {day + 1}
            </Text>
          </View>
        </ImageBackground>
        <View
          style={{
            flexWrap: "wrap",
            justifyContent: "space-around",
            position: "relative",
            flexDirection: "row",
            paddingHorizontal: 10,
            paddingVertical: 20,
          }}
        >
          <DayDetailsEntry
            title="Schema"
            renderIcon={(props) => <Entypo name="list" {...props} />}
            onPress={() => navigation.navigate("Schedule", { day })}
          />
          <DayDetailsEntry
            title="Dagopdracht"
            renderIcon={(props) => (
              <MaterialIcons name="assignment" {...props} />
            )}
            onPress={() => navigation.navigate("Assignment", { day })}
          />
          <DayDetailsEntry
            title="Tips / te zien"
            renderIcon={(props) => <FontAwesome5 name="landmark" {...props} />}
            onPress={() => navigation.navigate("Tips", { day })}
          />
          <DayDetailsEntry
            title="Nuttige info"
            renderIcon={(props) => <AntDesign name="infocirlce" {...props} />}
            onPress={() => navigation.navigate("Info", { day })}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

function DayDetailsEntry({
  title,
  onPress,
  renderIcon,
}: {
  title: string;
  onPress: () => void;
  renderIcon: (props: Omit<IconProps<string>, "name">) => React.ReactElement;
}) {
  return (
    <View
      style={{
        width: "40%",
        marginVertical: 10,
        alignItems: "center",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
      <Pressable
        android_ripple={{ color: "#BBC4DB", borderless: true }}
        onPress={onPress}
        style={({ pressed }) => ({
          alignItems: "center",
          padding: 20,
          width: "100%",
          minHeight: 100,
          backgroundColor:
            Platform.OS === "ios" && pressed ? "#BBC4DB" : "#EEF2FC",
        })}
      >
        {renderIcon({ size: 48, color: "#1E2E5D" })}
        <Text
          style={{
            fontSize: 16,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 10,
          }}
        >
          {title}
        </Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
  },
});
