import { View, Text } from "../Themed";
import { Contact } from "../../types/contacts";
import { Image } from "react-native";

export function ContactEntry({
  firstName,
  lastName,
  title,
  phoneNumber,
  image,
  email,
}: Contact) {
  return (
    <View
      style={{ marginBottom: 16, flexDirection: "row", alignItems: "center" }}
    >
      {!!image && (
        <Image
          source={{ uri: image }}
          style={{
            width: 80,
            height: 80,
            marginRight: 10,
            borderRadius: 10,
          }}
        />
      )}
      <View>
        <Text selectable={true} style={{ fontSize: 16, fontWeight: "bold" }}>
          {firstName} {lastName}
        </Text>
        {title !== undefined && (
          <Text selectable={true} style={{ opacity: 0.6, fontWeight: "bold" }}>
            {title}
          </Text>
        )}
        <Text selectable={true}>{phoneNumber}</Text>
        {/* <Text selectable={true}>{email}</Text> */}
      </View>
    </View>
  );
}
