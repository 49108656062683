import React from "react";
import {
  Alert,
  Button,
  ImageBackground,
  Platform,
  Pressable,
  StyleSheet,
} from "react-native";

import { ScrollView, Text, View } from "../components/Themed";
import { ProgramStackScreenProps } from "../types/navigation";
import { Schedule } from "../types/schedule";

import Images from "../constants/Images";
import { AntDesign } from "@expo/vector-icons";
import { useAppData } from "../AppData";

export default function ProgramScreen({
  navigation,
  route,
}: ProgramStackScreenProps<"Program">) {
  const { schedule } = useAppData();
  return (
    <ScrollView style={styles.container}>
      {schedule.map((_, index) => {
        return (
          <DayEntry
            key={index}
            day={index}
            onPress={() => navigation.navigate("Day", { day: index })}
            locked={schedule[index].locked}
          />
        );
      })}
    </ScrollView>
  );
}

function DayEntry({
  day,
  onPress,
  locked,
}: {
  day: number;
  onPress: () => void;
  locked?: boolean;
}) {
  return (
    <ImageBackground
      source={locked ? { uri: "" } : Images[day]}
      style={{
        borderRadius: 10,
        overflow: "hidden",
        marginHorizontal: 20,
        marginVertical: 10,
        backgroundColor: "gray",
      }}
      imageStyle={{ resizeMode: "cover" }}
    >
      <View
        style={{
          backgroundColor: "rgba(0,0,0,.3)",
        }}
      >
        <Pressable
          android_ripple={{ color: "black" }}
          onPress={onPress}
          style={({ pressed }) => ({
            minHeight: 120,
            justifyContent: "space-between",
            flexDirection: "row",
            backgroundColor:
              Platform.OS === "ios" && pressed
                ? "rgba(255,255,255,0.3)"
                : "transparent",
          })}
          disabled={locked}
        >
          <Text
            style={{
              color: "white",
              fontSize: 20,
              fontWeight: "bold",
              textShadowColor: "black",
              textShadowRadius: 20,
              padding: 20,
            }}
          >
            Dag {day + 1}
          </Text>
          {locked && (
            <AntDesign
              name="lock1"
              color="white"
              size={48}
              style={{ padding: 20, alignSelf: "center" }}
            />
          )}
        </Pressable>
      </View>
    </ImageBackground>
  );
}

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: "bold",
  },
  header: {
    padding: 16,
  },
});
