import { Contact } from "../../types/contacts";
import { ContactEntry } from "./ContactEntry";

export function ContactList({ contacts }: { contacts: Contact[] }) {
  return (
    <>
      {contacts.map((c) => (
        <ContactEntry key={`${c.firstName} ${c.lastName}`} {...c} />
      ))}
    </>
  );
}
